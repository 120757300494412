
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { dark, syntaxHighlighterPrism } from "@mdx-deck/themes";
import { Box } from "theme-ui";
export const theme = { ...syntaxHighlighterPrism,
  ...dark
};
export const Highlighted = ({
  children
}) => <Box color="tomato" style={{
  display: "inline-block"
}} mdxType="Box">
    {children}
  </Box>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Steps = makeShortcode("Steps");
const Notes = makeShortcode("Notes");
const layoutProps = {
  theme,
Highlighted
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`DEFI: The Deep Dark End`}</h1>
    <p>{`Your funds are unsafe`}</p>
    <hr></hr>
    <h2>{`I'm going to steal from you...`}</h2>
    <hr></hr>
    <h2>{`\\$ whoami`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Raymond Yeh`}</li>
        <li parentName="ul">{`Sofware Developer @ GovTech`}</li>
        <li parentName="ul">{`Author @ geek.sg`}</li>
        <li parentName="ul">{`Invested in cryptocurrency since 2017`}</li>
        <li parentName="ul">{`Arbitrage | Market Making | Flash Loan | Audit | Exploit`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Basic Building Blocks of Defi`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Ether`}</li>
        <li parentName="ul">{`Tokens (DAI, USDC, cDAI, UNI-ETH-USDC, COMP, UNI)`}</li>
        <li parentName="ul">{`Decentralized Exchanges (Uniswap, Balancer, Curve)`}</li>
        <li parentName="ul">{`Lending Platform (Compound, dydx, Aave, Maker)`}</li>
        <li parentName="ul">{`Asset Management (Balancer, Yearn, Harvest)`}</li>
        <li parentName="ul">{`Insurance (Nexus Mutual)`}</li>
        <li parentName="ul">{`etc`}</li>
      </ul>
    </Steps>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`Ether `}{`<`}{`>`}{` Currency (SGD / USD)`}</li>
        <li parentName="ul">{`Tokens `}{`<`}{`>`}{` Asset / Derivatives / Debt / etc`}</li>
        <li parentName="ul">{`Decentralized Exchanges `}{`<`}{`>`}{` Stock Exchanges (SGX / NYSE) / OTC`}</li>
        <li parentName="ul">{`Lending Platform `}{`<`}{`>`}{` Banks / Brokerage`}</li>
        <li parentName="ul">{`Asset Management `}{`<`}{`>`}{` Asset Management (Blackrock / Vanguard/ Berkshire Hathaway)`}</li>
        <li parentName="ul">{`Insurance `}{`<`}{`>`}{` Insurance (AIA / Prudential / Great Eastern)`}</li>
        <li parentName="ul">{`etc`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <h2>{`Let's Farm At The Deep End`}</h2>
    <small>#degen #ape #apy #uniswapgems</small>
    <hr></hr>
    <h2>{`Farm Template 101`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Stake `}<Highlighted mdxType="Highlighted">{`insert popular tokens`}</Highlighted>{` tokens with `}<Highlighted mdxType="Highlighted">{`insert AMM`}</Highlighted>{`, get LP token`}</li>
        <li parentName="ul">{`Stake token in `}<Highlighted mdxType="Highlighted">{`insert created contract`}</Highlighted>{`, get `}<Highlighted mdxType="Highlighted">{`insert veggie token`}</Highlighted></li>
        <li parentName="ul">{`Stake `}<Highlighted mdxType="Highlighted">{`insert veggie token`}</Highlighted>{` with `}<Highlighted mdxType="Highlighted">{`insert popular token`}</Highlighted>{`, get more `}<Highlighted mdxType="Highlighted">{`insert veggie token`}</Highlighted></li>
        <li parentName="ul">{`Sell `}<Highlighted mdxType="Highlighted">{`insert veggie token`}</Highlighted>{` anytime`}</li>
        <li parentName="ul">{`Profit!`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`SushiSwap`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Stake `}<Highlighted mdxType="Highlighted">{`USDC`}</Highlighted>{` `}{`&`}{` `}<Highlighted mdxType="Highlighted">{`ETH`}</Highlighted>{` tokens with Uniswap, get `}<Highlighted mdxType="Highlighted">{`UNI:ETH-USDC`}</Highlighted>{` token`}</li>
        <li parentName="ul">{`Stake `}<Highlighted mdxType="Highlighted">{`UNI:ETH-USDC`}</Highlighted>{` in `}<Highlighted mdxType="Highlighted">{`masterchef contract`}</Highlighted>{`, get `}<Highlighted mdxType="Highlighted">{`SUSHI`}</Highlighted></li>
        <li parentName="ul">{`Stake `}<Highlighted mdxType="Highlighted">{`SUSHI`}</Highlighted>{` with `}<Highlighted mdxType="Highlighted">{`ETH`}</Highlighted>{`, get even more `}<Highlighted mdxType="Highlighted">{`SUSHI`}</Highlighted></li>
        <li parentName="ul">{`Sell `}<Highlighted mdxType="Highlighted">{`SUSHI`}</Highlighted>{` anytime`}</li>
        <li parentName="ul">{`Profit!`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Masterchef Contract`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`contract MasterChef is Ownable {
    using SafeMath for uint256;
    using SafeERC20 for IERC20;

    struct UserInfo {
        uint256 amount;
        uint256 rewardDebt;
    }

    struct PoolInfo {
        IERC20 lpToken;
        uint256 allocPoint;
        uint256 lastRewardBlock;
        uint256 accSushiPerShare;
    }


    SushiToken public sushi;
    address public devaddr;
    uint256 public bonusEndBlock;
    uint256 public sushiPerBlock;
    uint256 public constant BONUS_MULTIPLIER = 10;

    IMigratorChef public migrator;
    PoolInfo[] public poolInfo;

    mapping (uint256 => mapping (address => UserInfo)) public userInfo;
    uint256 public totalAllocPoint = 0;
    uint256 public startBlock;

    event Deposit(address indexed user, uint256 indexed pid, uint256 amount);
    event Withdraw(address indexed user, uint256 indexed pid, uint256 amount);
    event EmergencyWithdraw(address indexed user, uint256 indexed pid, uint256 amount);

    constructor(
        SushiToken _sushi,
        address _devaddr,
        uint256 _sushiPerBlock,
        uint256 _startBlock,
        uint256 _bonusEndBlock
    ) public {
        sushi = _sushi;
        devaddr = _devaddr;
        sushiPerBlock = _sushiPerBlock;
        bonusEndBlock = _bonusEndBlock;
        startBlock = _startBlock;
    }

    function poolLength() external view returns (uint256) {
        return poolInfo.length;
    }

    function add(uint256 _allocPoint, IERC20 _lpToken, bool _withUpdate) public onlyOwner {
        if (_withUpdate) {
            massUpdatePools();
        }
        uint256 lastRewardBlock = block.number > startBlock ? block.number : startBlock;
        totalAllocPoint = totalAllocPoint.add(_allocPoint);
        poolInfo.push(PoolInfo({
            lpToken: _lpToken,
            allocPoint: _allocPoint,
            lastRewardBlock: lastRewardBlock,
            accSushiPerShare: 0
        }));
    }

    function set(uint256 _pid, uint256 _allocPoint, bool _withUpdate) public onlyOwner {
        if (_withUpdate) {
            massUpdatePools();
        }
        totalAllocPoint = totalAllocPoint.sub(poolInfo[_pid].allocPoint).add(_allocPoint);
        poolInfo[_pid].allocPoint = _allocPoint;
    }

    function setMigrator(IMigratorChef _migrator) public onlyOwner {
        migrator = _migrator;
    }

    function migrate(uint256 _pid) public {
        require(address(migrator) != address(0), "migrate: no migrator");
        PoolInfo storage pool = poolInfo[_pid];
        IERC20 lpToken = pool.lpToken;
        uint256 bal = lpToken.balanceOf(address(this));
        lpToken.safeApprove(address(migrator), bal);
        IERC20 newLpToken = migrator.migrate(lpToken);
        require(bal == newLpToken.balanceOf(address(this)), "migrate: bad");
        pool.lpToken = newLpToken;
    }

    function getMultiplier(uint256 _from, uint256 _to) public view returns (uint256) {
        if (_to <= bonusEndBlock) {
            return _to.sub(_from).mul(BONUS_MULTIPLIER);
        } else if (_from >= bonusEndBlock) {
            return _to.sub(_from);
        } else {
            return bonusEndBlock.sub(_from).mul(BONUS_MULTIPLIER).add(
                _to.sub(bonusEndBlock)
            );
        }
    }

    function pendingSushi(uint256 _pid, address _user) external view returns (uint256) {
        PoolInfo storage pool = poolInfo[_pid];
        UserInfo storage user = userInfo[_pid][_user];
        uint256 accSushiPerShare = pool.accSushiPerShare;
        uint256 lpSupply = pool.lpToken.balanceOf(address(this));
        if (block.number > pool.lastRewardBlock && lpSupply != 0) {
            uint256 multiplier = getMultiplier(pool.lastRewardBlock, block.number);
            uint256 sushiReward = multiplier.mul(sushiPerBlock).mul(pool.allocPoint).div(totalAllocPoint);
            accSushiPerShare = accSushiPerShare.add(sushiReward.mul(1e12).div(lpSupply));
        }
        return user.amount.mul(accSushiPerShare).div(1e12).sub(user.rewardDebt);
    }

    function massUpdatePools() public {
        uint256 length = poolInfo.length;
        for (uint256 pid = 0; pid < length; ++pid) {
            updatePool(pid);
        }
    }

    function updatePool(uint256 _pid) public {
        PoolInfo storage pool = poolInfo[_pid];
        if (block.number <= pool.lastRewardBlock) {
            return;
        }
        uint256 lpSupply = pool.lpToken.balanceOf(address(this));
        if (lpSupply == 0) {
            pool.lastRewardBlock = block.number;
            return;
        }
        uint256 multiplier = getMultiplier(pool.lastRewardBlock, block.number);
        uint256 sushiReward = multiplier.mul(sushiPerBlock).mul(pool.allocPoint).div(totalAllocPoint);
        sushi.mint(devaddr, sushiReward.div(10));
        sushi.mint(address(this), sushiReward);
        pool.accSushiPerShare = pool.accSushiPerShare.add(sushiReward.mul(1e12).div(lpSupply));
        pool.lastRewardBlock = block.number;
    }

    function deposit(uint256 _pid, uint256 _amount) public {
        PoolInfo storage pool = poolInfo[_pid];
        UserInfo storage user = userInfo[_pid][msg.sender];
        updatePool(_pid);
        if (user.amount > 0) {
            uint256 pending = user.amount.mul(pool.accSushiPerShare).div(1e12).sub(user.rewardDebt);
            safeSushiTransfer(msg.sender, pending);
        }
        pool.lpToken.safeTransferFrom(address(msg.sender), address(this), _amount);
        user.amount = user.amount.add(_amount);
        user.rewardDebt = user.amount.mul(pool.accSushiPerShare).div(1e12);
        emit Deposit(msg.sender, _pid, _amount);
    }

    function withdraw(uint256 _pid, uint256 _amount) public {
        PoolInfo storage pool = poolInfo[_pid];
        UserInfo storage user = userInfo[_pid][msg.sender];
        require(user.amount >= _amount, "withdraw: not good");
        updatePool(_pid);
        uint256 pending = user.amount.mul(pool.accSushiPerShare).div(1e12).sub(user.rewardDebt);
        safeSushiTransfer(msg.sender, pending);
        user.amount = user.amount.sub(_amount);
        user.rewardDebt = user.amount.mul(pool.accSushiPerShare).div(1e12);
        pool.lpToken.safeTransfer(address(msg.sender), _amount);
        emit Withdraw(msg.sender, _pid, _amount);
    }

    function emergencyWithdraw(uint256 _pid) public {
        PoolInfo storage pool = poolInfo[_pid];
        UserInfo storage user = userInfo[_pid][msg.sender];
        pool.lpToken.safeTransfer(address(msg.sender), user.amount);
        emit EmergencyWithdraw(msg.sender, _pid, user.amount);
        user.amount = 0;
        user.rewardDebt = 0;
    }

    function safeSushiTransfer(address _to, uint256 _amount) internal {
        uint256 sushiBal = sushi.balanceOf(address(this));
        if (_amount > sushiBal) {
            sushi.transfer(_to, sushiBal);
        } else {
            sushi.transfer(_to, _amount);
        }
    }

    function dev(address _devaddr) public {
        require(msg.sender == devaddr, "dev: wut?");
        devaddr = _devaddr;
    }
}
`}</code></pre>
    <hr></hr>
    <h1>{`Round 1`}</h1>
    <img src="/assets/hackerchef.png" style={{
      height: "45%"
    }} />
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`"Stumbled" on `}<inlineCode parentName="li">{`ihackyourcoins1.com`}</inlineCode></li>
        <li parentName="ul">{`Approved your `}<Highlighted mdxType="Highlighted">{`UNI:ETH-USDC`}</Highlighted></li>
        <li parentName="ul">{`Staked your `}<Highlighted mdxType="Highlighted">{`UNI:ETH-USDC`}</Highlighted></li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`HACKED`}</h1>
    <p>{`Your `}<Highlighted mdxType="Highlighted">{`UNI:ETH-USDC`}</Highlighted>{` are mine`}</p>
    <hr></hr>
    <h2>{`What happened?`}</h2>
    <p>{`"Social Engineering Attack"`}</p>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`What smart contract did you interact with?`}</li>
        <li parentName="ul">{`Is smart contract on website same the one you've interacted?`}</li>
        <li parentName="ul">{`Is smart contract verified?`}</li>
        <li parentName="ul">{`Does the code look okay?`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`Round 2`}</h1>
    <img src="/assets/hackerchef.png" style={{
      height: "45%"
    }} />
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`"Stumbled" on `}<inlineCode parentName="li">{`ihackyourcoins2.com`}</inlineCode></li>
        <li parentName="ul">{`Checked for social engineering attacks`}</li>
        <li parentName="ul">{`Checked the masterchef code is exactly the same as masterchef`}</li>
        <li parentName="ul">{`Staked your `}<Highlighted mdxType="Highlighted">{`UNI:ETH-USDC`}</Highlighted></li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`HACKED`}</h1>
    <p>{`Your `}<Highlighted mdxType="Highlighted">{`ETH Liquidity`}</Highlighted>{` are mine`}</p>
    <p>{`Your `}<Highlighted mdxType="Highlighted">{`UNI:ETH-USDC`}</Highlighted>{` are mine`}</p>
    <hr></hr>
    <h2>{`What happened?`}</h2>
    <p>{`"Composition & Initialisation of smart contracts"`}</p>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Who has priviledged access to contract?`}</li>
        <li parentName="ul">{`Is timelock configured properly?`}</li>
        <li parentName="ul">{`What token contract is it using?`}</li>
        <li parentName="ul">{`How much tokens are in circulation vs farmed?`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Migration To Malicious Contract`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setMigrator(IMigratorChef _migrator) public onlyOwner {
    migrator = _migrator;
}
...
function migrate(uint256 _pid) public {
    require(address(migrator) != address(0), "migrate: no migrator");
    PoolInfo storage pool = poolInfo[_pid];
    IERC20 lpToken = pool.lpToken;
    uint256 bal = lpToken.balanceOf(address(this));
    lpToken.safeApprove(address(migrator), bal);
    IERC20 newLpToken = migrator.migrate(lpToken);
    require(bal == newLpToken.balanceOf(address(this)), "migrate: bad");
    pool.lpToken = newLpToken;
}
`}</code></pre>
    <hr></hr>
    <h2>{`Different Reward Ratio`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function updatePool(uint256 _pid) public {
    ...
    sushi.mint(devaddr, sushiReward.div(10));
    sushi.mint(address(this), sushiReward);
    ...
}
`}</code></pre>
    <hr></hr>
    <h2>{`Access to Minting Function on `}<Highlighted mdxType="Highlighted">{`Token Contract`}</Highlighted></h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function _mint(address account, uint256 amount) internal virtual {
    require(account != address(0), "ERC20: mint to the zero address");
    _beforeTokenTransfer(address(0), account, amount);
    _totalSupply = _totalSupply.add(amount);
    _balances[account] = _balances[account].add(amount);
    emit Transfer(address(0), account, amount);
}
`}</code></pre>
    <hr></hr>
    <h2>{`Round 3`}</h2>
    <img src="/assets/hackerchef.png" style={{
      height: "45%"
    }} />
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`"Stumbled" on `}<inlineCode parentName="li">{`ihackyourcoins3.com`}</inlineCode></li>
        <li parentName="ul">{`Done all due diligence`}</li>
        <li parentName="ul">{`Farmed for a while now with nice profits`}</li>
        <li parentName="ul">{`Withdrew staked `}<Highlighted mdxType="Highlighted">{`UNI:ETH-USDC`}</Highlighted>{` and `}<Highlighted mdxType="Highlighted">{`HACK`}</Highlighted></li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`HACKED`}</h1>
    <p>{`Your `}<Highlighted mdxType="Highlighted">{`UNI:ETH-USDC`}</Highlighted>{` are mine`}</p>
    <hr></hr>
    <h2>{`What happened?`}</h2>
    <p>{`"Some contract transferred all my tokens away!"`}</p>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`Did you remove your approval limit from the malicious contract at ihackyourcoins1.com?!?!?`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Round nth`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Arithmetic`}</li>
        <li parentName="ul">{`Access Controls`}</li>
        <li parentName="ul">{`Economics Exploits (Flash loan/Arbitrage/Front-running)`}</li>
        <li parentName="ul">{`Re-entrancy`}</li>
        <li parentName="ul">{`Memory Overwrite`}</li>
        <li parentName="ul">{`DoS`}</li>
        <li parentName="ul">{`External Contract Reference`}</li>
        <li parentName="ul">{`Delegate Call`}</li>
        <li parentName="ul">{`etc`}</li>
      </ul>
    </Steps>
    <Notes mdxType="Notes">
      <p>{`Recent Rugs: `}<a parentName="p" {...{
          "href": "https://boxmining.com/newsletter-11/?amp#DeFi/yield_farming_scams_are_ruining_things_for_the_space"
        }}>{`https://boxmining.com/newsletter-11/?amp#DeFi/yield_farming_scams_are_ruining_things_for_the_space`}</a></p>
      <ul>
        <li parentName="ul">{`Arithmetic`}</li>
        <li parentName="ul">{`Access Controls`}
          <ul parentName="li">
            <li parentName="ul">{`Parity Wallet`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Economics Exploits (Flash loan/Arbitrage/Front-running)`}
          <ul parentName="li">
            <li parentName="ul">{`Harvest finance hack`}
              <ul parentName="li">
                <li parentName="ul">{`Flashloan + arbitrage`}</li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://rekt.ghost.io/harvest-finance-rekt/"
                  }}>{`https://rekt.ghost.io/harvest-finance-rekt/`}</a></li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://twitter.com/bneiluj/status/1320686478486347778"
                  }}>{`https://twitter.com/bneiluj/status/1320686478486347778`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">{`Re-entrancy`}
          <ul parentName="li">
            <li parentName="ul">{`dForce LendF.me (24M)`}
              <ul parentName="li">
                <li parentName="ul">{`ERC777`}</li>
                <li parentName="ul">{`Walkthrough: `}<a parentName="li" {...{
                    "href": "https://twitter.com/FrankResearcher/status/1251771278719098886"
                  }}>{`https://twitter.com/FrankResearcher/status/1251771278719098886`}</a></li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://defirate.com/dforce-hack/"
                  }}>{`https://defirate.com/dforce-hack/`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">{`Memory Overwrite`}
          <ul parentName="li">
            <li parentName="ul">{`Memory layout, 2^256 rows`}</li>
          </ul>
        </li>
        <li parentName="ul">{`DoS`}
          <ul parentName="li">
            <li parentName="ul">{`Parity Wallet (152M) `}{`[Nov'17]`}
              <ul parentName="li">
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://cointelegraph.com/news/parity-multisig-wallet-hacked-or-how-come"
                  }}>{`https://cointelegraph.com/news/parity-multisig-wallet-hacked-or-how-come`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">{`External Contract Reference`}
          <ul parentName="li">
            <li parentName="ul">{`STA (500k)`}
              <ul parentName="li">
                <li parentName="ul">{`Deflationary token `}{`&`}{` DEX`}</li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://1inch-exchange.medium.com/balancer-hack-2020-a8f7131c980e"
                  }}>{`https://1inch-exchange.medium.com/balancer-hack-2020-a8f7131c980e`}</a></li>
              </ul>
            </li>
            <li parentName="ul">{`UniBomb (50k)`}
              <ul parentName="li">
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://medium.com/@unibombproject/unibomb-a-technical-overview-f7ec33d1db75"
                  }}>{`https://medium.com/@unibombproject/unibomb-a-technical-overview-f7ec33d1db75`}</a></li>
              </ul>
            </li>
            <li parentName="ul">{`BZx (630k)`}
              <ul parentName="li">
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://www.coindesk.com/defi-project-bzx-exploited-for-second-time-in-a-week-loses-630k-in-ether"
                  }}>{`https://www.coindesk.com/defi-project-bzx-exploited-for-second-time-in-a-week-loses-630k-in-ether`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">{`Delegate Call`}
          <ul parentName="li">
            <li parentName="ul">{`Parity Wallet (150M) `}{`[Jul'17]`}
              <ul parentName="li">
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://blog.openzeppelin.com/parity-wallet-hack-reloaded/"
                  }}>{`https://blog.openzeppelin.com/parity-wallet-hack-reloaded/`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">{`etc`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <h2>{`What's in a HACK?`}</h2>
    <Notes mdxType="Notes">
      <p><a parentName="p" {...{
          "href": "https://twitter.com/bneiluj/status/1320686478486347778"
        }}>{`https://twitter.com/bneiluj/status/1320686478486347778`}</a></p>
      <ul>
        <li parentName="ul">{`It this a hack or an arbitrage?`}</li>
        <li parentName="ul">{`Who has the rights to the money?`}</li>
        <li parentName="ul">{`Should law enforcers be involved?`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <h2>{`DEFI as a social experiment`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Voluntary`}</li>
        <li parentName="ul">{`Code as Law`}</li>
        <li parentName="ul">{`(Even Bad Code)`}</li>
        <li parentName="ul">{`Time Dilation`}</li>
        <li parentName="ul">{`Opensourced `}{`&`}{` Public Information`}</li>
      </ul>
    </Steps>
    <Notes mdxType="Notes">
      <p>{`Longitudinal Study`}</p>
      <ul>
        <li parentName="ul">{`Governments | Central Banks (CBDC) | Law Makers`}</li>
        <li parentName="ul">{`Economists (esp Game Theorist) | Sociologists | Technologists`}</li>
        <li parentName="ul">{`Financers (derivatives and financial innovations like flash loan)`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <h2>{`Protecting yourself`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Start slow`}</li>
        <li parentName="ul">{`Use hardware wallet`}</li>
        <li parentName="ul">{`Check all smart contracts you interact with`}</li>
        <li parentName="ul">{`Limit access to your tokens`}</li>
        <li parentName="ul">{`Take calculated risk before "apeing" into any project`}</li>
        <li parentName="ul">{`Learn to read smart contracts`}</li>
        <li parentName="ul">{`Write smart contracts`}</li>
        <li parentName="ul">{`Learn the intricacies of Ethereum`}</li>
        <li parentName="ul">{`Dive deep into smart contract security`}</li>
      </ul>
    </Steps>
    <Notes mdxType="Notes">
  
      <ul>
        <li parentName="ul">{`Use hardware wallet `}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.ledger.com/"
          }}>{`https://www.ledger.com/`}</a>{` `}</li>
        <li parentName="ul">{`Check all smart contract you interact with`}
          <ul parentName="li">
            <li parentName="ul">{`contract address `}</li>
            <li parentName="ul">{`function name `}</li>
            <li parentName="ul">{`function parameters`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Limit access to your tokens `}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://revoke.cash/"
              }}>{`https://revoke.cash/`}</a>{` `}</li>
          </ul>
        </li>
        <li parentName="ul">{`Take calculated risk before "apeing" into any project `}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://legacy.geek.sg/blog/bet-sizing-with-kelly-kelly-criterion"
          }}>{`https://legacy.geek.sg/blog/bet-sizing-with-kelly-kelly-criterion`}</a>{` `}</li>
        <li parentName="ul">{`Learn to smart contracts`}</li>
        <li parentName="ul">{`Write smart contracts`}</li>
        <li parentName="ul">{`Learn the intricacies of Ethereum`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://dasp.co/"
              }}>{`https://dasp.co/`}</a>{` `}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://consensys.github.io/smart-contract-best-practices/known_attacks/"
              }}>{`https://consensys.github.io/smart-contract-best-practices/known_attacks/`}</a>{` `}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://solidity-05.ethernaut.openzeppelin.com/"
              }}>{`https://solidity-05.ethernaut.openzeppelin.com/`}</a>{` `}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://ethernaut.gdshive.io/"
              }}>{`https://ethernaut.gdshive.io/`}</a></li>
          </ul>
        </li>
        <li parentName="ul">{`Take a masterclass on smart contract security`}
          <ul parentName="li">
            <li parentName="ul">{`Launching on `}<a parentName="li" {...{
                "href": "https://geek.sg/"
              }}>{`https://geek.sg/`}</a></li>
          </ul>
        </li>
      </ul>
    </Notes>
    <hr></hr>
    <h1>{`END`}</h1>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;